import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledFlexbox = styled.div`
  margin-top: 4rem;
  > * {
    + * {
      margin-top: 2rem;
    }
  }

  ${props => props.theme.media.md`
    display: flex;
    justify-content: center;
    > * {
      flex: 1 0 35.7rem;
      + * {
      margin-top: 0;
    }
    }
  `}
`

function Flexbox({ children, ...props }) {
  return <StyledFlexbox {...props}>{children}</StyledFlexbox>
}

export default Flexbox

Flexbox.propTypes = {
  children: PropTypes.node.isRequired,
}
