import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { renderFontStyles, renderFontFamily } from "helpers/styles"

const StyledSubheader = styled.span`
  font-family: ${props =>
    renderFontFamily(
      props.theme.fontFamilies,
      props.theme.typography,
      "secondaryBody"
    )};
  ${props => renderFontStyles(props.theme.typography, "secondaryBody")}

  ${props => props.theme.media.md`
    br {
      display: none;
    }
  `}
`

function Subheader({ children, ...props }) {
  return <StyledSubheader {...props}>{children}</StyledSubheader>
}

export default Subheader

Subheader.propTypes = {
  children: PropTypes.node.isRequired,
}
