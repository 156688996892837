const fontFamilies = [
  {
    key: "rubik",
    title: "Rubik",
    value: "Rubik, sans-serif",
  },
  {
    key: "georgia",
    title: "Georgia",
    value: "Georgia, serif",
  },
  {
    key: "arial",
    title: "Arial",
    value: "Arial, sans-serif",
  },
];

const typography = {
  h1: {
    font: "rubik",
    fontSize: "40px",
    letterSpacing: "10px",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: "700",
    color: "currentColor",
    lineHeight: "normal",
    margin: "1rem 0 4rem",
  },
  h2: {
    font: "rubik",
    fontSize: "30px",
    letterSpacing: "0",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: "700",
    color: "currentColor",
    lineHeight: "normal",
  },
  h3: {
    font: "rubik",
    fontSize: "27px",
    letterSpacing: "0",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: "700",
    color: "currentColor",
    lineHeight: "normal",
  },
  h4: {
    font: "rubik",
    fontSize: "23px",
    letterSpacing: "0",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: "700",
    color: "currentColor",
    lineHeight: "normal",
  },
  h5: {
    font: "rubik",
    fontSize: "20px",
    letterSpacing: "2.5px",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "currentColor",
    lineHeight: "normal",
    margin: "0 0 0 0",
  },
  h6: {
    font: "rubik",
    fontSize: "15px",
    letterSpacing: "0",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "currentColor",
    lineHeight: "normal",
  },
  quote: {
    font: "rubik",
    fontSize: "20px",
    letterSpacing: "0",
    textTransform: "none",
    fontStyle: "italic",
    fontWeight: "300",
    color: "currentColor",
    lineHeight: "normal",
    margin: `0 0 0 0`,
  },
  subheader: {
    font: "rubik",
    fontSize: "20px",
    letterSpacing: "0",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: "300",
    color: "currentColor",
    lineHeight: "normal",
  },
  body: {
    font: "rubik",
    fontSize: "16px",
    letterSpacing: "0",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: "300",
    color: "currentColor",
    lineHeight: "normal",
  },
  secondaryBody: {
    font: "rubik",
    fontSize: "11px",
    letterSpacing: "0",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: "300",
    color: "currentColor",
    lineHeight: "1.6rem",
    margin: "4rem 2rem 0 2rem",
  },
  figCaption: {
    font: "rubik",
    fontSize: "12px",
    letterSpacing: "0",
    textTransform: "none",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "currentColor",
    lineHeight: "normal",
  },
  button: {
    font: "rubik",
    fontSize: "12px",
    letterSpacing: "0",
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "normal",
  },
}

const link = {
  default: Object.assign({}, typography.body, {
    color: "rgba(0, 0, 0, 1)",
    borderBottom: "3px solid transparent",
    textDecoration: "none",
    fontWeight: "700",
    padding: "0 0 .5rem 0"
  }),
  hover: {
    color: "rgba(0, 0, 0, 1)",
    borderBottom: "3px solid rgba(0, 0, 0, 1)",
  },
  visited: {
    color: "rgba(0, 0, 0, 1)",
  },
}

export { fontFamilies, typography, link };
