const global = {
  googleFont: "Rubik:300,700",
  fontFace: [],
  body: {
    background: "#ffffff",
    color: "#000000",
  },
}

export { global };
