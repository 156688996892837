import React from "react"
import { Layout } from "components"
import { Flexbox, Subheader } from "lib"

function Index({ ...props }) {
  return (
    <Layout>
      <div>
        <img src="/logo.svg" alt="9ten logo" />
      </div>
      <div>
        <h1>GROUP</h1>
      </div>
      <div>
        <h5>UNDER CONSTRUCTION</h5>
        <p>
          We’re currently building our new website.
          <br />
          For more information, contact us on:
        </p>
        <p>
          <a href="mailto:info@9ten-group.com">info@9ten-group.com</a>
        </p>
      </div>
      <Flexbox>
        <div>
          Russell Slade
          <br />
          Chief Technical Officer
          <br />
          T:+44 (0) 7545 465 555
          <br />
          E: Russell@9ten-group.com
        </div>
        <div>
          Jessica Cullen
          <br />
          Chief Executive
          <br />
          T:+44 (0) 7545 635 244
          <br />
          E: Jessica@9ten-group.com
        </div>
      </Flexbox>
      <Subheader>
        9ten Group Ltd | Company Reg 11926423 |<br /> Office: Rodney House,
        Clifton Down Road, Bristol, BS8 4AL
      </Subheader>
    </Layout>
  )
}

export default Index
