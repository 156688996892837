const modal = {
  bg: `rgba(#000, 0.5)`,
  container: {
    bg: `#fff`,
    boxShadow: ``,
    borderRadius: 0,
  },
};

export { modal };
